export const fixItem = (item, isTitle = false) => {
    var accents =
        'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    var accentsOut =
        'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';
    item = item && item.split('');

    var strLen = item && item.length;
    var i, x;
    for (i = 0; i < strLen; i++) {
        if ((x = accents.indexOf(item[i])) != -1) {
            item[i] = accentsOut[x];
        }
    }

    if (isTitle) {
        return item && item.includes('-')
            ? item.join('').toLowerCase().split(' ').join('').split('-').join('')
            : item && item.join('').toLowerCase().split(' ').join('');
    } else {
        return item && item.join('').toLowerCase().split(' ').join('-');
    }
};