// Libs
import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import readingTime from 'reading-time';

// Components
import SocialShare from 'components/units/unitPages/details/socialShare';
import { formatDate } from 'utils/formatDate';
import { createDoctorData } from 'utils/createDoctorData.js';

// Images
import IconWithOutPhoto from 'images/icons/perfil-blog-withoutimg.png';
import IconMandalaHSL from 'images/icons/mandala_hsl.svg';

// Styles
import {
  Container,
  BoxPerfil,
  BoxAvatarPerfil,
  BoxTextsPerfil,
  BoxTextInfoAndSocialShare,
  Text,
  BoxLinks,
  TagCloudLink,
} from './postDateStyle';

const PostDate = ({ post, location, doctorsApi }) => {
  const doctorsData =
    post?.doctors
      ?.map(doctor => {
        return createDoctorData({
          doctorCMS: doctor,
          doctorsAPI: doctorsApi,
        });
      })
      ?.filter(e => e) ?? [];

  const handleReadingTime = () => {
    if (post && post.markdown && post.markdown.length > 0 && post.markdown[0]) {
      return readingTime(post.markdown[0]);
    } else if (post?.localizations?.[0]?.markdown.length > 0) {
      return readingTime(post.localizations[0].markdown);
    } else {
      return undefined;
    }
  };
  const formattedAuthors =
    doctorsData?.length > 0
      ? doctorsData?.map(doctor => ({
          avatar: doctor.photo
            ? doctor.photo?.url
            : doctor.id_api
            ? `https://corpoclinico.hsl.org.br/ImagemVisualizar.ashx?codigoMedico=${doctor.id_api}`
            : IconWithOutPhoto,
          fullName: doctor.nameWithAssignment,
          profileLink: doctor.active
            ? `/encontre-seu-medico/${doctor.slug}`
            : '',
          specialties: doctor.specialties,
        }))
      : post?.author?.length > 0
      ? post?.author?.map(authorName => ({
          avatar: IconWithOutPhoto,
          fullName: authorName,
          profileLink: '',
          specialties: [],
        }))
      : [
          {
            avatar: IconMandalaHSL,
            fullName: 'Hospital Sírio-Libanês',
            profileLink: '',
            specialties: [],
          },
        ];

  const SocialShareComponent = (location, post) => (
    <SocialShare
      location={location}
      title={(post && post.title) || '-'}
      isBlogPost
      containerPaddingMobile="0"
      shareBoxFlexGap="0.625rem"
      iconsBoxMargin="0"
      iconsBoxMarginMobile="0"
    />
  );

  const EspecialistyList = () =>
    formattedAuthors.map((author, index) => {
      if (formattedAuthors.length > 1) return null;
      if (!author?.specialties?.length) return null;

      return (
        <Text key={index}>
          Especialista em{' '}
          {author.specialties.map(
            ({ name, slug, customUrl, unity }, i, arr) => (
              <TagCloudLink
                to={`${
                  customUrl
                    ? customUrl
                    : `/especialidades-medicas/${
                        unity.includes('bsb') ? 'brasilia' : 'sao-paulo'
                      }/${slug}`
                }`}
                key={name}
                lastItem={i === arr.length - 1}
              >
                {name}
              </TagCloudLink>
            )
          )}
        </Text>
      );
    });
  return (
    <Container>
      <BoxPerfil>
        <BoxAvatarPerfil>
          {formattedAuthors
            .slice(0)
            .reverse()
            .map((author, index) => (
              <img
                key={index}
                src={author.avatar}
                alt={`Imagem do ${author.fullName}`}
              />
            ))}
        </BoxAvatarPerfil>
        <BoxTextsPerfil>
          <Text isName>
            {formattedAuthors.map((author, index) =>
              author.profileLink ? (
                <span key={index}>
                  <Link to={author.profileLink}>{author.fullName}</Link>
                </span>
              ) : (
                <span key={index}>{author.fullName}</span>
              )
            )}
          </Text>
          <EspecialistyList />
          <BoxTextInfoAndSocialShare>
            <Text>
              {(post && post.date && formatDate(post.date)) ||
                (post && formatDate(post && post.date))}
              {post && post.date && ' · '}
              {handleReadingTime() !== undefined &&
                `${
                  handleReadingTime().text.split('read').join('') || '-'
                } de leitura`}
            </Text>
            <BoxLinks className="boxLinks-mobile">
              {SocialShareComponent(location, post)}
            </BoxLinks>
          </BoxTextInfoAndSocialShare>
        </BoxTextsPerfil>
      </BoxPerfil>
      <BoxLinks className="boxLinks-desktop">
        {SocialShareComponent(location, post)}
      </BoxLinks>
    </Container>
  );
};

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          doctorsApi {
            doctors {
              id
              name
              gender
              crm
              email
              phone
              phonecommercial
              specialty
              employee
              estab
              state
            }
          }
        }
      `}
      render={response => {
        return (
          <PostDate doctorsApi={response?.doctorsApi?.doctors} {...props} />
        );
      }}
    />
  );
};
