import React, { Fragment } from 'react';
import {
  ContainerPostFeriadoBlog,
  ContainerImgUnitPostFeriado,
  ImgUnitPostFeriado,
  DayAndDatePostFeriado,
  CityAndStatePostFeriado,
  LinkToPostFeriado,
  HoursAndSocialNetwork,
  ContainerUnidades,
  TitleUnits,
  DescriptionUnits,
  HolidayScheduleLabelAusencia,
  HolidayScheduleLabelAlojamento,
  Part,
  HolidayScheduleLabelHours,
} from './style';
export function PostFeriado({ postData }) {
  const data = postData.postFeriados[0];

  const orderedByUnits = data?.units.reduce((acc, unit) => {
    const foundUnitData = data?.unidadeFeriadoFuncionamento?.find(
      unitHolyday => unitHolyday.units?.[0]?.id === unit.id
    );

    if (foundUnitData) {
      const mergedUnitData = { ...unit, ...foundUnitData };
      return [...acc, mergedUnitData];
    }

    return acc;
  }, []);

  const arrayTitleUnits = orderedByUnits.map(mapUnits => (
    <Fragment key={mapUnits.id}>
      <Part borderTopContainer />
      <ContainerImgUnitPostFeriado>
        <ImgUnitPostFeriado
          src={mapUnits?.units?.[0]?.addressAndInformationJSON?.img_link}
          alt={mapUnits?.units?.[0]?.name}
        />
      </ContainerImgUnitPostFeriado>
      <TitleUnits>{mapUnits?.units?.[0]?.name}</TitleUnits>
      <DescriptionUnits>
        <CityAndStatePostFeriado>
          {mapUnits?.units?.[0]?.addressAndInformationJSON?.address}
        </CityAndStatePostFeriado>
        <CityAndStatePostFeriado>
          {`${mapUnits?.units?.[0]?.addressAndInformationJSON?.city}, ${mapUnits?.units?.[0]?.addressAndInformationJSON?.state}`}
        </CityAndStatePostFeriado>
        <LinkToPostFeriado to={mapUnits?.units?.[0]?.link}>
          Mais sobre essa unidade
        </LinkToPostFeriado>
        {mapUnits.datainfo?.map(mapUnidadeFeriadoFunc =>
          mapUnidadeFeriadoFunc.date?.map((mapDate, index) => (
            <Fragment key={index}>
              <Part className={`${'border-' + index}`} />
              <DayAndDatePostFeriado>
                {mapDate?.targetDay}
              </DayAndDatePostFeriado>
              <DayAndDatePostFeriado className="date-post-feriado">
                {mapDate?.targetDate}
              </DayAndDatePostFeriado>
              {mapDate.alojamento.map((mapAlojamento, ind) => (
                <Fragment key={ind}>
                  <HoursAndSocialNetwork>
                    {mapAlojamento?.labelAusencia ? (
                      <>
                        <HolidayScheduleLabelAusencia>
                          {mapAlojamento?.labelAusencia}
                        </HolidayScheduleLabelAusencia>
                      </>
                    ) : (
                      <>
                        <HolidayScheduleLabelAlojamento>
                          {mapAlojamento?.label}
                        </HolidayScheduleLabelAlojamento>
                        <HolidayScheduleLabelHours>
                          {mapAlojamento?.schedules}
                        </HolidayScheduleLabelHours>
                      </>
                    )}
                  </HoursAndSocialNetwork>
                </Fragment>
              ))}
            </Fragment>
          ))
        )}
      </DescriptionUnits>
    </Fragment>
  ));
  return (
    <ContainerPostFeriadoBlog>
      <ContainerUnidades>{arrayTitleUnits}</ContainerUnidades>
    </ContainerPostFeriadoBlog>
  );
}
