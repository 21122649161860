import styled from 'styled-components';
import { Link } from 'gatsby';
import { device } from '../device';

export const ContainerPostFeriadoBlog = styled.div`
  display: flex;
  width: 100%;
`;

export const CityAndStatePostFeriado = styled.p`
  display: flex;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #8f9194;
`;

export const DayAndDatePostFeriado = styled.h2`
  display: flex;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: #231f20;
  margin-bottom: 0;

  @media ${device.mobile} {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
`;

export const LinkToPostFeriado = styled(Link)`
  display: flex;
  margin-top: 0.625rem;
  margin-bottom: 3.125rem;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #45a7df;

  @media ${device.mobile} {
    margin-bottom: 1.875rem;
  }
`;

export const HoursAndSocialNetwork = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;

  @media ${device.mobile} {
    flex-direction: column;
  }

  :last-child {
    margin-bottom: 4.375rem;

    @media ${device.mobile} {
      margin-bottom: 1.25rem;
    }
  }
`;

export const ContainerUnidades = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .date-post-feriado {
    margin-bottom: 1.875rem;

    @media ${device.mobile} {
      margin-bottom: 1.25rem;
    }
  }
`;

export const TitleUnits = styled.h2`
  display: flex;
  margin-bottom: 0.625rem;

  @media ${device.mobile} {
    font-size: 1.125rem;
  }
`;

export const DescriptionUnits = styled.div`
  display: flex;
  flex-direction: column;

  .border-0 {
    margin-top: 0;
    margin-bottom: 0;
    border: none;
  }
`;

export const HolidayScheduleLabelAlojamento = styled.p`
  display: flex;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #231f20;

  @media ${device.mobile} {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
`;
export const HolidayScheduleLabelAusencia = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #8f9194;
`;

export const HolidayScheduleLabelHours = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.625rem;
  text-align: right;
  color: #8f9194;

  @media ${device.mobile} {
    font-size: 0.875rem;
    line-height: 1.375rem;
    text-align: start;
  }
`;

export const Part = styled.div`
  margin-top: 1.875rem;
  margin-bottom: ${props =>
    props.borderTopContainer ? '4.375rem' : '3.125rem'};
  border-top: 1px solid #c6c8cc;
  :first-child {
    border-top: ${props => props.borderTopContainer && 'none'};
    margin: ${props => props.borderTopContainer && '0'};
  }

  @media ${device.mobile} {
    margin-top: 0.625rem;
    margin-bottom: 1.875rem;
  }
`;

export const ImgUnitPostFeriado = styled.img`
  display: flex;
  width: 100%;
`;

export const ContainerImgUnitPostFeriado = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 1.875rem;
`;
