// Libs
import React, { Fragment, useContext, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled, { css } from 'styled-components';
import { graphql } from 'gatsby';
import { HeaderContext } from 'context/header';
import ReactGA from 'react-ga4';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import BreadCrumb from 'components/breadCrumb';
import PostDate from 'components/blog/postDate';
import ListPosts from 'components/blog/listPosts';
import DescriptionPage from 'components/contactUs/descriptionPage';
import { fixItem } from 'utils/fixItem';
import { PostFeriado } from 'components/post-feriado';
import Components from 'components/CmsComponents/';

// Image
import shareImg from 'images/logos/logo-hsl-share.png';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;

  @media ${device.laptop} {
    padding: 0 1.25rem;
    display: flex;
    flex-direction: column;
  }

  > h2 {
    grid-column: 4 / span 6;
    text-align: left;
    font: normal normal bold 1.5rem/2.125rem;
    color: #231f20;
  }

  h6 {
    font: normal normal 300 1.875rem/2.5rem;
    color: #8f9194;
    grid-column: 4 / span 6;

    @media ${device.mobile} {
      font-size: 1.5rem;
    }
  }
`;

const Part = styled.div`
  grid-column: ${props => props.gridColumn};

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 1.875rem;
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: ${props => (props.isMarginFullWidth ? '4.375rem' : '0')};

    @media ${device.laptop} {
      margin-bottom: ${props => (props.isMarginFullWidth ? '1.25rem' : '0')};
    }
  }

  ${props =>
    props.isContent &&
    `
    a {
      color: #45a7df;
    }

    ul, ol {
      li {
        font-size: 1rem;
        color: #231f20;
        font-size: 1rem;
        line-height: 1.625rem;

        @media ${device.laptop} {
          font-size: 0.875rem;
          line-height: 1.376rem;
        }
      }
    }
  `}

  > p {
    font-size: 1rem;
    color: #231f20;
    font-size: 1rem;
    line-height: 1.625rem;
    padding-bottom: 1.875rem;

    @media ${device.laptop} {
      font-size: 0.875rem;
      line-height: 1.376rem;
    }

    > strong {
      color: #231f20;
      grid-column: 4 / span 6;
    }

    > em {
      color: #8f9194;
      font-size: 0.875rem;
      grid-column: 4 / span 6;
      font-style: normal;
    }

    img {
      width: 100%;
    }
  }

  > p:first-child {
    > em {
      display: flex;
      justify-content: center;
    }
  }

  iframe {
    width: 100%;
    height: calc(770px / 1.77);

    @media ${device.laptop} {
      height: calc((100vw - 40px) / 1.77);
    }
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    margin: ${props => props.isMarginFullWidth && '0 -1.25rem 1.25rem'};
  }

  ${props =>
    props.marginTop &&
    css`
      margin-top: 4.375rem;

      @media ${device.laptop} {
        margin-top: 1.875rem;
      }
    `}

  ${props =>
    props.padding &&
    css`
      padding: 4.375rem 0;

      @media ${device.laptop} {
        padding: 1.875rem 0;
      }
    `}

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #c6c8cc;
    `}
`;

const ContainerImg = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.laptop} {
    width: 100%;
    margin: 0;
  }
`;

const ContainerTable = styled.div`
  width: 100%;

  table {
    width: 100%;
    min-width: 48.125rem;

    th:nth-child(3) {
      width: 50%;
    }
  }

  @media ${device.laptop} {
    overflow-x: scroll;

    table {
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
  }
`;

const RenderPage = (haveMarkdown = [], data, location) => {
  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  const imageRender = ({ alt, src }) => {
    return (
      <ContainerImg>
        <img src={src} alt={alt} />
      </ContainerImg>
    );
  };

  const tableRender = ({ node, ...props }) => {
    return (
      <ContainerTable>
        <table {...props}></table>
      </ContainerTable>
    );
  };

  const components = data?.postFeriados[0]?.highlightContent && [
    {
      ...data?.postFeriados[0]?.highlightContent,
      __typename: 'GraphCMS_HighlightContent',
    },
  ];

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('9.75rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('7.75rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  useEffect(() => {
    // Inicializando o Google Analytics
    if (Boolean(data?.tagGa)) {
      ReactGA.initialize(data?.tagGa);
      ReactGA.send('pageview');
    }
  }, []);

  return (
    <GridContainer>
      <Part gridColumn={'2 / -2'}>
        <BreadCrumb
          markdown={`1. [Home](/) › [Blog](/blog) › [${
            data?.topic || data?.localizations?.[0]?.topic
          }](/blog/${fixItem(
            data?.topic || data?.localizations?.[0]?.topic,
            true
          )}) › ${data?.title || data?.localizations?.[0]?.title}`}
          marginTop
        />
      </Part>
      <Part gridColumn={'4 / -4'}>
        <DescriptionPage
          dataPostBlog={data?.localizations?.[0] || data || '-'}
          widthLarge
          isBlog
          responsiveWithoutPaddingBottom
          contentWidth="100%"
        />
        <PostDate
          post={data?.localizations?.[0] || data || '-'}
          location={location}
        />
      </Part>
      {data?.postFeriados.length <= 0 && haveMarkdown.length > 1 && (
        <Part gridColumn={'2 / -2'} isContent>
          <ReactMarkdown
            plugins={[remarkGfm]}
            children={haveMarkdown[0]}
            renderers={{
              image: imageRender,
              table: tableRender,
            }}
            allowDangerousHtml={true}
          />
        </Part>
      )}
      <Part gridColumn={'4 / -4'} isContent>
        {data?.postFeriados.length > 0 ? (
          <PostFeriado postData={data} />
        ) : haveMarkdown.length < 1 ? (
          <></>
        ) : haveMarkdown.length === 1 ? (
          haveMarkdown.map((item, index) => {
            return (
              <ReactMarkdown
                key={index}
                plugins={[remarkGfm]}
                children={item}
                renderers={{
                  image: imageRender,
                  table: tableRender,
                }}
                allowDangerousHtml={true}
              />
            );
          })
        ) : (
          haveMarkdown.map((item, index) => {
            if (index === 0) return <Fragment key={index}></Fragment>;
            return (
              <ReactMarkdown
                key={index}
                plugins={[remarkGfm]}
                children={item}
                renderers={{
                  image: imageRender,
                  table: tableRender,
                }}
                allowDangerousHtml={true}
              />
            );
          })
        )}
      </Part>

      {components && components.length > 0 && (
        <Part gridColumn={'1 / -1'} isMarginFullWidth>
          {components.length >= 1 &&
            components?.map(component => {
              return Components(component);
            })}
        </Part>
      )}
      {data?.postToPost?.length > 0 && (
        <Part gridColumn={'2 / -2'} padding borderTop marginTop>
          <ListPosts
            title={data?.postToPost[0]?.topic || '-'}
            headerLinkTitle=""
            headerLink=""
            postsToShow={3}
            posts={data?.postToPost}
          />
        </Part>
      )}
    </GridContainer>
  );
};

const Page = ({
  data: {
    gcms: { posts },
  },
  location,
  pageContext,
}) => {
  const data = posts[0];

  const titleSeo =
    data?.titleSeo ||
    pageContext?.title ||
    (pageContext?.localizations &&
      pageContext?.localizations[0].length > 0 &&
      pageContext?.localizations[0].title) ||
    'Postagem';
  const descriptionSEO =
    data?.descriptionSeo || (pageContext && pageContext.descriptionSeo) || '-';
  const dataSeo = [
    {
      seo: {
        metaTitle: titleSeo,
        metaDescription: descriptionSEO,
      },
    },
  ];

  const haveMarkdown =
    (data?.markdown?.length > 0 && data.markdown) ||
    (data?.localizations?.length > 0 && data.localizations[0].markdown) ||
    undefined;

  return (
    <Layout>
      <SEO
        dataSeo={dataSeo}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${pageContext?.ogImageSeo?.handle}` ||
          shareImg
        }
      />
      {RenderPage(haveMarkdown, data, location)}
    </Layout>
  );
};

export const PageQuery = graphql`
  query PostPageQuery($id: ID) {
    gcms {
      posts(where: { id: $id }) {
        postFeriados {
          id
          units {
            id
          }
          unidadeFeriadoFuncionamento(locales: [pt_BR, en, es]) {
            id
            datainfo
            units(locales: [pt_BR, en, es]) {
              id
              link
              name
              addressAndInformationJSON
            }
          }
          highlightContent(locales: [pt_BR, en, es]) {
            id
            name
            type
            backgroundColorInitial {
              hex
            }
            backgroundColorFinal {
              hex
            }
            title
            customTitle
            description
            images {
              handle
              width
              height
              url
            }
            fragments {
              id
              name
              type
              markdown
            }
          }
        }
        id
        title
        topic
        slugPost
        markdown
        date
        author
        doctors(first: 1000, locales: [pt_BR, en]) {
          id
          id_api
          active
          assignment
          name
          photo {
            handle
            url
            height
            width
          }
        }
        description
        tagGa
        titleSeo
        descriptionSeo
        ogImageSeo {
          handle
          width
          height
        }
        localizations {
          markdown
          date
          title
          topic
          date
          author
          doctors(locales: [pt_BR, en]) {
            id
            id_api
            active
            assignment
            name
            photo {
              handle
              url
              height
              width
            }
            state
            specialties {
              name
              unity
              slug
              customUrl
              id
            }
          }
          description
          titleSeo
          descriptionSeo
          ogImageSeo {
            handle
            width
            height
          }
          assetpicker {
            handle
            width
            height
          }
          authorImage {
            handle
            width
            height
          }
        }
        ogImageSeo {
          handle
          width
          height
        }
        assetpicker {
          handle
          height
          width
        }
        postToPost {
          id
          slugPost
          title
          topic
          markdown
          author
          doctors(first: 1000, locales: [pt_BR, en]) {
            id
            id_api
            active
            assignment
            name
            photo {
              handle
              url
              height
              width
            }
          }
          titleSeo
          descriptionSeo
          ogImageSeo {
            handle
            width
            height
          }
          assetpicker {
            handle
            width
            height
          }
          authorImage {
            handle
            width
            height
          }
          localizations {
            markdown
            date
            title
            topic
            date
            author
            doctors(first: 1000, locales: [pt_BR, en]) {
              id
              id_api
              active
              assignment
              name
              photo {
                handle
                url
                height
                width
              }
            }
            description
            titleSeo
            descriptionSeo
            assetpicker {
              handle
              width
              height
            }
            authorImage {
              handle
              width
              height
            }
          }
          categories {
            tag
          }
        }
        categories {
          tag
        }
      }
    }
  }
`;

export default Page;
