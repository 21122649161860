import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { device } from 'components/device';

export const Container = styled.section`
  margin-top: 1.875rem;
  margin-bottom: 3.125rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${device.laptop} {
    margin: 0 0 1.5rem;
    flex-direction: column;
    align-items: flex-start;
  }
  @media ${device.mobile} {
    align-items: start;
  }
`;

export const BoxPerfil = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > img,
  .authorImg {
    margin-right: 0.625rem;
    min-width: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
  }

  @media ${device.laptop} {
    margin-bottom: 0.75rem;
    flex-direction: column;
    align-items: flex-start;

    > img,
    .authorImg {
      margin-bottom: 0.625rem;
    }
  }
`;

export const BoxAvatarPerfil = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-right: 1.25rem;

  img {
    width: 2.5rem;
    height: 2.5rem;
    border: 2px solid #ffffff;
    border-radius: 50%;

    object-fit: cover;
    object-position: center;

    & + img {
      margin-right: -1rem;
    }
  }

  @media ${device.laptop} {
    margin-right: 0;
    margin-bottom: 0.625rem;
  }
`;

export const BoxTextsPerfil = styled.div`
  width: 100%;
  max-width: 28.875rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  line-height: 1.375rem;

  @media ${device.laptop} {
    max-width: unset;
  }
`;

export const BoxTextInfoAndSocialShare = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Text = styled.p`
  margin-right: ${props => props.isName && '0.5rem'};
  padding-bottom: 0;
  color: ${props => (props.isName ? '#231f20' : '#8f9194')};
  text-align: left;
  font-size: 0.875rem;

  ${props =>
    props.isName &&
    css`
      span {
        display: inline;

        a {
          color: #45a7df;
          transition: text-decoration 0.2s;
        }

        a:hover {
          text-decoration: underline;
        }
      }

      span::after {
        content: ' · ';
      }

      span:last-of-type::after {
        content: '';
      }

      @media ${device.mobile} {
        span {
          display: block;
        }
      }
    `}
`;

export const BoxLinks = styled.div`
  width: auto;

  &.boxLinks-desktop {
    display: block;
  }

  &.boxLinks-mobile {
    display: none;
  }

  @media ${device.laptop} {
    &.boxLinks-desktop {
      display: none;
    }

    &.boxLinks-mobile {
      display: block;
    }
  }
`;
export const TagCloudLink = styled(Link)`
  color: #45a7df;
  font-size: 0.875rem;
  ${props =>
    props.lastItem
      ? css`
          &::after {
            content: '';
          }
        `
      : css`
          &::after {
            content: ', ';
          }
        `}
`;
